import React, { useState, useEffect } from "react";
import img from "../../images/Rectangle 1871.svg";

function WorkingWithUs({ data }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="workWithUs py-0">
      <div className="global-margin">
        <div
          className={`row pb-5 ${
            isMobileView ? "flex-column-reverse" : "flex-row"
          }`}
        >
          <div className={`col-lg-7 col-md-12`}>
            <div className="awsTitle text-uppercase pt-5 pb-3 ">
              {data.WorkingSection.title}
            </div>
            <div className="workWithUsHeading text-start">
              {data.WorkingSection.heading1}{" "}
              <span>{data.WorkingSection.heading2}</span>
            </div>
          </div>
          <div
            className={`col-lg-5 col-md-12 ${
              isMobileView ? "mt-4 text-center" : "text-end"
            }`}
          >
            <img
              src={img}
              className={`pt-5 px-4 ${isMobileView ? "w-100" : "w-100"}`}
              alt="img"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkingWithUs;
